<template>
  <div v-show="isAllowed" @click="openModal" class="btn btn-primary btn--big">{{ buttonName }}</div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'sell-confirm-booking-fee-btn',
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    bookingInfo: {
      required: true,
    },
  },
  mixins: [HelperMixin, ButtonMixin],
  data() {
    return {
      actionName: 'CONFIRM_BOOKING_FEE',
      actionType: 'SELL_TRANSACTION',
    };
  },
  computed: {
    checkBankAccountInbox() {
      return this.$store.state.v2.profile.checkBankAccountInbox;
    },
  },
  watch: {
    checkBankAccountInbox(val) {
      if (val && this.isAllowed) {
        this.openModal();
      }
    },
  },
  methods: {
    async openModal() {
      this.$modal.show('sell-confirm-booking-fee', {
        uuid: this.applicationUuid,
        buttonName: this.buttonName,
        bookingInfo: this.bookingInfo,
      });
    },
  },
};
</script>

<style scoped></style>
